// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-what-we-do-tsx": () => import("./../../../src/pages/what_we_do.tsx" /* webpackChunkName: "component---src-pages-what-we-do-tsx" */),
  "component---src-pages-where-we-are-tsx": () => import("./../../../src/pages/where_we_are.tsx" /* webpackChunkName: "component---src-pages-where-we-are-tsx" */),
  "component---src-pages-who-we-are-tsx": () => import("./../../../src/pages/who_we_are.tsx" /* webpackChunkName: "component---src-pages-who-we-are-tsx" */)
}

